const newPanelInfo = (docCookies) => {
    const panelInfo = document.querySelector(".panel-uk");
    if (panelInfo) {
        const closeInfo = panelInfo.querySelector(".panel-uk__close");
        if (closeInfo) {
            closeInfo.addEventListener("click", function(e){
                docCookies.setCookie("new-panel", 1, 365, "/");
                panelInfo.classList.add("d-none");
            });
        }
    }
}
export default newPanelInfo;
