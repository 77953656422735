function stickyHeader() {
    const header = document.querySelector("header");
    if (header && !header.classList.contains("header--reader")) {
        const basketWidget = document.querySelector(".basket-widget");
        if (window.scrollY > 0) {
            header.classList.remove("header--black");
            header.classList.add("header");
        }
        window.addEventListener("scroll", function(e){
            if (window.scrollY > 0) {
                header.classList.remove("header--black");
                header.classList.add("header");
            }
            else {
                if (basketWidget) {
                    if (!basketWidget.classList.contains("open")) {
                        header.classList.add("header--black");
                        header.classList.remove("header");
                    }
                }
                else {
                    header.classList.add("header--black");
                    header.classList.remove("header");
                }
            }
        });
    }
}

export default stickyHeader;
