const subscriptionList = () => {
    const subscriptionAside = document.querySelector(".subscription__aside-list");
    const subscriptionTitle = document.querySelector(".subscription__aside-title");
    const subscriptionItems = document.querySelectorAll("[data-item-id]");
    const titleBtn = document.querySelector(".c-category__title-btn");
    const closeBtn = document.querySelector(".c-category__options-close");
    const secondWrap = document.querySelector(".c-category__second-wrap");
    const body = document.querySelector("body");
    const scrollToTop = document.getElementById("scroll-top");
    if (subscriptionAside) {
        const subscriptionAsideItems = subscriptionAside.querySelectorAll("[data-id]");
        subscriptionAside.addEventListener("click", function(e){
            if (e.target.dataset.hasOwnProperty("id")) {
                for (var i = 0; i < subscriptionAsideItems.length; i++) {
                    subscriptionAsideItems[i].classList.remove("active");
                }
                e.target.classList.add("active");
                if (e.target.dataset.id != "all") {
                    for (var i = 0; i < subscriptionItems.length; i++) {
                        subscriptionItems[i].dataset.active = "false";
                    }
                    let activeEl = document.querySelector("[data-item-id='" + e.target.dataset.id + "'][data-type='" + e.target.dataset.type + "']");
                    activeEl.dataset.active = "true";
                    if (activeEl.dataset.type == "item") {
                        activeEl.parentElement.dataset.active = "true";
                    }
                    else {
                        for (var i = 0; i < activeEl.children.length; i++) {
                            activeEl.children[i].dataset.active = "true";
                        }
                    }
                }
                else {
                    for (var i = 0; i < subscriptionItems.length; i++) {
                        subscriptionItems[i].dataset.active = "true";
                    }
                }
                if (scrollToTop) {
                    scrollToTop.click();
                }
            }
        });
        const url = document.URL
        let idStart = url.indexOf("id=");
        if (idStart > - 1) {
            idStart += 3;
        }
        const hashIndex = url.indexOf("#");
        const ampIndex = url.indexOf("&");
        if (idStart != -1) {
            let id = url.slice(idStart);
            if (hashIndex > idStart) {
                id = url.slice(idStart, hashIndex);
            }
            if (ampIndex > idStart) {
                id = url.slice(idStart, ampIndex);
            }
            console.log(id);
            document.querySelector(".subscription__aside-item[data-id='" + id + "']").click();
        }
    }
    if (subscriptionTitle) {
        subscriptionTitle.addEventListener("click", function(e){
            this.parentElement.classList.toggle("open");
        });
    }
    if (titleBtn) {
        titleBtn.addEventListener("click", function(e){
            secondWrap.classList.toggle("open");
        });
    }
    if (closeBtn) {
        closeBtn.addEventListener("click", function(e){
            secondWrap.classList.remove("open");
        });
    }
}
export default subscriptionList;
