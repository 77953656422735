function viewSwitch(docCookies) {
    const gridList = document.getElementsByClassName("grid-list")[0];
    const gridBtns = document.getElementsByClassName("view")[0];
    const gridIcons = document.getElementsByClassName("view__icon");

    if (gridList && gridBtns) {
        gridBtns.addEventListener("click", function(e) {
            if (typeof(e.target.dataset.gridBtn) != "undefined") {
                for (var i = 0; i < gridIcons.length; i++) {
                    gridIcons[i].classList.remove("active");
                }
                gridList.dataset.grid = e.target.dataset.gridBtn;
                e.target.classList.add("active");
                docCookies.setCookie("listView", e.target.dataset.gridBtn, 30, "/");
            }
        });
    }
}

export default viewSwitch;
