const panelSubscription = (XHRWrap) => {
    const subscriptionWrap = document.querySelector(".js-panel-subscription");
    if (subscriptionWrap) {
        let subscriptionXHR;
        subscriptionWrap.addEventListener("click", function(e){
            if (e.target.classList.contains("js-extension-toggle")) {
                let item = e.target.parentElement.parentElement;
                if (item.classList.contains("open")) {
                    item.classList.remove("open");
                }
                else {
                    let allItems = item.parentElement.children;
                    for (var i = 0; i < allItems.length; i++) {
                        allItems[i].classList.remove("open");
                    }
                    item.classList.add("open");
                }
            }
        });
    }
}
export default panelSubscription;
