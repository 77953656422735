
// Folder - START

var allFolderInputs = document.querySelectorAll('.folder__form-item-select input[type="checkbox"]');
var allFolderNumber = document.querySelectorAll('.folder__form-item-select input[type="number"]');
var folderRow       = document.getElementsByClassName('folder__form-bottom-row')[0];
var companyOrNot    = document.querySelector('.folder__form-radio-items.person');
var nipInput        = document.querySelector('.folder__form-nip');

if(allFolderInputs) {
    for(var i = 0; i < allFolderInputs.length; i++) {
        allFolderInputs[i].addEventListener('change',function() {
            var inputInside = this.parentNode.parentNode.querySelector('input[type="number"]');
            if(this.checked) {
                if(inputInside.value < 1) {
                    inputInside.value = 1;
                }
            } else {
                inputInside.value = '';
            }
            checkInputValue();
        })
    }
}

if(allFolderNumber) {
    for(var i = 0; i < allFolderNumber.length; i++) {
        allFolderNumber[i].addEventListener('keyup',function(){
            var inputInsideCheckbox = this.parentNode.parentNode.querySelector('input[type="checkbox"]');
            if(this.value > 0) {
                inputInsideCheckbox.checked = true;
            } else {
                inputInsideCheckbox.checked = false;
            }
            checkInputValue();
        })
    }
}

function checkInputValue() {
    breakloop: for(var j = 0; j < allFolderInputs.length; j++) {
        var inputInside = allFolderInputs[j].parentNode.parentNode.querySelector('input[type="number"]');
        if(inputInside.value > 0) {
            folderRow.classList.remove('hidden');
            break breakloop;
        } else {
            folderRow.classList.add('hidden');
        }
    }
}

if(companyOrNot) {
    var getComapnyInputs = companyOrNot.getElementsByTagName('input');
    for(var i = 0; i < getComapnyInputs.length; i++) {
        getComapnyInputs[i].addEventListener('change',function(){
            companyOrNotChange(getComapnyInputs);
        })
    }
    companyOrNotChange(getComapnyInputs);
}

function companyOrNotChange(inputs) {
    for(var i = 0; i < inputs.length; i++) {
        if(inputs[i].checked == true && inputs[i].value == 'company') {
            nipInput.classList.remove('hidden');
        } else {
            nipInput.classList.add('hidden');
        }
    }
}

// Show hidden text

var hiddenTextButton = document.getElementsByClassName('folder-show-text-js')[0];
var hiddenText       = document.getElementsByClassName('folder__form-register-hidden-text')[0];

if(hiddenTextButton) {
    hiddenTextButton.addEventListener('click',function(){
        if(hiddenText.classList.contains('show')) {
            hiddenText.classList.remove('show');
            hiddenTextButton.innerText = 'Rozwiń tekst';
        } else {
            hiddenText.classList.add('show');
            hiddenTextButton.innerText = 'Zwiń tekst';
        }
    })
}

// Show hidden different address

var hiddenAddress           = document.getElementsByClassName('different-address-js')[0];
var hiddenAddressInputs     = document.querySelectorAll('.folder__form-radio-items-item input');
var hiddenAddressWrapper    = document.getElementsByClassName('folder__form-hidden-address')[0];

if(hiddenAddress) {
    for(var i = 0; i < hiddenAddressInputs.length; i++) {
        console.log(hiddenAddressInputs[i]);
        hiddenAddressInputs[i].addEventListener('change',function(){
            if(hiddenAddress.checked == true) {
                hiddenAddressWrapper.classList.add('show');
            } else {
                hiddenAddressWrapper.classList.remove('show');
            }
        })
    }
}

// Show hidden code discount

var discountRadio       = document.querySelectorAll('.folder__form-discount input[type="radio"]');
var subscriptionFolder  = document.getElementById('avt-discount');
var codeFolder          = document.getElementById('code-discount');
var subsciptionWrapper  = document.getElementsByClassName('avt-discount-js')[0];
var codeWrapper         = document.getElementsByClassName('code-discount-js')[0];

if(discountRadio) {
    for(var i = 0; i < discountRadio.length; i++) {
        discountRadio[i].addEventListener('change',function(){
            if(subscriptionFolder.checked === true) {
                subsciptionWrapper.classList.add('show')
            } else {
                subsciptionWrapper.classList.remove('show')
            }

            if(codeFolder.checked === true) {
                codeWrapper.classList.add('show')
            } else {
                codeWrapper.classList.remove('show')
            }
        })
    }
}

// Folder - END