function widgetBasket(SimpleBar) {
    const header = document.querySelector("header");
    const basketWidgetBtn = document.querySelector(".c-navigation__btn.-basket");
    const basketWidgetWrapper = document.querySelector(".basket-widget__wrapper");
    const basketWidgetClose = document.querySelectorAll(".basket-widget__close");
    const basketWidget = document.querySelector(".basket-widget");
    const basketScroll = document.querySelector(".basket-widget .checkout__scroll-wrap");
    if (basketScroll) {
        const basketSimple = new SimpleBar(basketScroll, {autoHide: false});
    }

    if (basketWidget && basketWidgetBtn) {
        basketWidgetBtn.addEventListener("click", function(e){
            e.preventDefault();
            basketWidget.classList.toggle("open");
            basketWidgetWrapper.classList.toggle("open");
            if (window.scrollY == 0) {
                if (basketWidget.classList.contains("open")) {
                    header.classList.remove("header--black");
                    header.classList.add("header");
                }
                else {
                    header.classList.add("header--black");
                    header.classList.remove("header");
                }
            }
            else {
                if (basketWidget.classList.contains("open")) {
                    header.classList.remove("header--black");
                    header.classList.add("header");
                }

            }
        });
        for (var i = 0; i < basketWidgetClose.length; i++) {
            basketWidgetClose[i].addEventListener("click", function(e){
                e.preventDefault();
                basketWidget.classList.remove("open");
                basketWidgetWrapper.classList.remove("open");
                if (window.scrollY == 0) {
                    header.classList.add("header--black");
                    header.classList.remove("header");
                }
                else {
                    header.classList.remove("header--black");
                    header.classList.add("header");
                }
            });
        }
    }

}

export default widgetBasket;
