function freeShipping(docCookies) {
    const closeShipping = document.querySelector('.js-close-free-shipping');
    const shipping = document.querySelector('.c-free-shipping');

    if (closeShipping) {
        console.log(docCookies.showCookie("freeShipping"));
        if(docCookies.showCookie("freeShipping") == null) {
            shipping.style.display = "";
            docCookies.setCookie("freeShipping", 1, 30, "/");
        }
        closeShipping.addEventListener('click', function() {
            docCookies.setCookie("freeShipping", 1, 30, "/");
            shipping.classList.add('is-closed');
        });
    }
}

export default freeShipping;
