const loginPage = () => {
    const loginForms = document.querySelectorAll(".register-login__login-form, .register-login__form");
    const loader = document.querySelector(".register-login__loader");
    if (loginForms.length > 0) {
        for (var i = 0; i < loginForms.length; i++) {
            loginForms[i].addEventListener("submit", function(){
                loader.classList.add("loading");
            });
        }
    }
}
export default loginPage;
