const promoTop = () => {
    const promoTop = document.querySelector(".promo-top");
    if (promoTop) {
        const promoTopItems = promoTop.querySelectorAll(".promo-top__item");
        window.addEventListener("scroll", function(e){
            for (var i = 0; i < promoTopItems.length; i++) {
                promoTopItems[i].classList.remove("open");
            }
        });
        for (var i = 0; i < promoTopItems.length; i++) {
            promoTopItems[i].addEventListener("mouseenter", function(e){
                this.classList.add("open");
            });
            promoTopItems[i].addEventListener("mouseleave", function(e){
                this.classList.remove("open");
            });
            promoTopItems[i].addEventListener("touchstart", function(e){
                this.classList.toggle("open-mobile");
            });
        }
    }
}
export default promoTop;
