const fbPixel = () => {
    let viewContent = document.querySelector("[data-view-content='true']");
    if (viewContent) {
        let priceIdsElements = document.querySelectorAll(".product-article__purchase-item-buy-btn[data-price-id], .product-article__next-number-btn[data-price-id]");
        let priceIds = [];
        for (var i = 0; i < priceIdsElements.length; i++) {
            priceIds.push(priceIdsElements[i].dataset.priceId);
        }
        fbq('track', 'ViewContent', {
            content_name: viewContent.dataset.pixelName,
            content_category: viewContent.dataset.pixelCategory,
            content_ids: priceIds,
            content_type: viewContent.dataset.pixelType,
            value: viewContent.dataset.pixelValue,
            currency: viewContent.dataset.pixelCurrency
        });
    }
    let addPaymentInfo = document.querySelector("[data-pixel='AddPaymentInfo']");
    if (addPaymentInfo && typeof(pixelJSON) != "undefined") {
        fbq('track', 'AddPaymentInfo', pixelJSON);
    }

}
export default fbPixel;
