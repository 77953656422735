const partners = (XHRWrap) => {
    // Segments
    const segments = document.querySelector(".partners-segments");
    if (segments) {
        document.addEventListener("click", function(e){
            if (e.target.dataset.hasOwnProperty("segmentIndex")) {
                segments.dataset.segmentIndex = e.target.dataset.segmentIndex;
            }
        });
    }

    // Benefits dropdown text
    const benefitsItems = document.querySelectorAll(".partners-benefits__item");
    if (benefitsItems.length > 0) {
        for (var i = 0; i < benefitsItems.length; i++) {
            benefitsItems[i].addEventListener("click", function(e){
                if (this.classList.contains("active")) {
                    this.classList.remove("active");
                    let description = this.querySelector(".partners-benefits__item-description");
                    let wrap = this.querySelector(".partners-benefits__item-wrap");
                    description.style = "";
                    wrap.style = "";
                }
                else {
                    for (var j = 0; j < benefitsItems.length; j++) {
                        let description = benefitsItems[j].querySelector(".partners-benefits__item-description");
                        let wrap = benefitsItems[j].querySelector(".partners-benefits__item-wrap");
                        description.style = "";
                        wrap.style = "";
                        benefitsItems[j].classList.remove("active");
                    }
                    let description = this.querySelector(".partners-benefits__item-description");
                    let wrap = this.querySelector(".partners-benefits__item-wrap");
                    let descriptionStyle = window.getComputedStyle(description);
                    let height = parseFloat(descriptionStyle.getPropertyValue("height"));
                    let marginBottom = parseFloat(descriptionStyle.getPropertyValue("margin-bottom"));
                    wrap.style.maxHeight = height + marginBottom + "px";
                    this.classList.add("active");
                }
            });
        }
    }


    // Partners contact
    const partnersForm = document.querySelectorAll(".js-partners-contact");
    if (partnersForm.length > 0) {
        for (var i = 0; i < partnersForm.length; i++) {
            const inputs = partnersForm[i].querySelectorAll("input[data-form-data='true']");
            for (var j = 0; j < inputs.length; j++) {
                if (inputs[j].value != "") {
                    inputs[j].nextElementSibling.classList.add("input-filled");
                }
                else {
                    inputs[j].nextElementSibling.classList.remove("input-filled");
                }
            }
            partnersForm[i].addEventListener("change", function(e){
                const inputs = this.querySelectorAll("input[data-form-data='true']");
                for (var j = 0; j < inputs.length; j++) {
                    if (inputs[j].value != "") {
                        inputs[j].nextElementSibling.classList.add("input-filled");
                    }
                    else {
                        inputs[j].nextElementSibling.classList.remove("input-filled");

                    }
                }
            });
        }
    }
    const contactForm = document.querySelector("#partners-form");
    if (contactForm) {
        let contactMessage;
        let formSending = false;
        const formMessages = document.querySelectorAll(".checkout__form-message");
        const checkMessage = document.querySelector(".checkout__form-message.check");
        contactForm.addEventListener("submit", function(e){
            e.preventDefault();
            e.stopPropagation();
            if (formSending) {
                return;
            }
            for (var i = 0; i < formMessages.length; i++) {
                formMessages[i].classList.remove("active");
            }
            checkMessage.classList.add("active");
            formSending = true;
            contactMessage = new XHRWrap({
                url: "/contact",
                form: contactForm,
                additionalFormData: {
                    id: "kiosk-partners",
                }
            });
            contactMessage.send().then(
                result => {
                    console.log(result);
                    for (var i = 0; i < formMessages.length; i++) {
                        formMessages[i].classList.remove("active");
                        if (formMessages[i].classList.contains("succes") && result.result) {
                            formMessages[i].querySelector("p").innerText = result.message;
                            formMessages[i].classList.add("active");
                            contactForm.classList.add("disabled");
                            if (partnersStartForm) {
                                partnersStartForm.classList.add("disabled");
                            }
                            formSending = false;
                        }
                        if (formMessages[i].classList.contains("error") && !result.result) {
                            formMessages[i].querySelector("p").innerText = result.message;
                            formMessages[i].classList.add("active");
                            formSending = false;
                        }
                    }
                    fbq('track', 'Contact');
                },
                error => {
                    console.error(error);
                    for (var i = 0; i < formMessages.length; i++) {
                        formMessages[i].classList.remove("active");
                        if (formMessages[i].classList.contains("error")) {
                            formMessages[i].querySelector("p").innerHTML = "<span class='title'>UPS! COŚ POSZŁO NIE TAK</span><span class='subtitle'>Prosimy spróbować ponownie za chwilę</span>";
                            formMessages[i].classList.add("active");
                            formSending = false;
                        }
                    }
                }
            )
        });
    }
    const partnersStart = document.querySelector(".partners-start");
    if (partnersStart) {
        partnersStart.addEventListener("click", function(e){
            if (e.target.dataset.hasOwnProperty("close")) {
                this.classList.remove("open");
            }
        });
    }

    const partnersStartForm = document.querySelector("#partners-start-form");

    if (partnersStartForm) {
        let contactMessage;
        let formSending = false;
        const formMessages = document.querySelectorAll(".partners-start__form-message");
        const checkMessage = document.querySelector(".partners-start__form-message.check");
        partnersStartForm.addEventListener("submit", function(e){
            e.preventDefault();
            e.stopPropagation();
            if (formSending) {
                return;
            }
            this.parentElement.classList.add("sending");
            for (var i = 0; i < formMessages.length; i++) {
                formMessages[i].classList.remove("active");
            }
            checkMessage.classList.add("active");
            formSending = true;
            contactMessage = new XHRWrap({
                url: "/contact",
                form: partnersStartForm,
                additionalFormData: {
                    id: "kiosk-partners",
                }
            });
            contactMessage.send().then(
                result => {
                    for (var i = 0; i < formMessages.length; i++) {
                        formMessages[i].classList.remove("active");
                        if (formMessages[i].classList.contains("succes") && result.result) {
                            formMessages[i].querySelector("p").innerText = result.message;
                            formMessages[i].classList.add("active");
                            partnersStartForm.classList.add("disabled");
                            if (contactForm) {
                                contactForm.classList.add("disabled");
                            }
                            formSending = false;
                        }
                        if (formMessages[i].classList.contains("error") && !result.result) {
                            formMessages[i].querySelector("p").innerText = result.message;
                            formMessages[i].classList.add("active");
                            formSending = false;
                        }
                    }
                    fbq('track', 'Contact');
                    partnersStartForm.parentElement.classList.remove("sending");
                },
                error => {
                    console.error(error);
                    for (var i = 0; i < formMessages.length; i++) {
                        formMessages[i].classList.remove("active");
                        if (formMessages[i].classList.contains("error")) {
                            formMessages[i].querySelector("p").innerHTML = "<span class='title'>UPS! COŚ POSZŁO NIE TAK</span><span class='subtitle'>Prosimy spróbować ponownie za chwilę</span>";
                            formMessages[i].classList.add("active");
                            formSending = false;
                        }
                    }
                    partnersStartForm.parentElement.classList.remove("sending");
                }
            )
        });
    }
    if (partnersStart) {
        document.addEventListener("click", function(e){
            if (e.target.dataset.hasOwnProperty("open") && e.target.dataset.open == "contact") {
                e.preventDefault();
                partnersStart.classList.add("open");
            }
        })
    }
}

export default partners;
