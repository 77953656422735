const panelEpapers = (Isotope, Swiper, SimpleBar) => {
    // E-wydania
    const panelEpaper = document.querySelector(".panel__epapers");
    const panelEpaperMenu = document.querySelector(".panel__select-wrap");
    const panelEpaperYears = document.querySelector(".panel__section-menu-wrap--years");
    const panelSelectMagazine = document.querySelector(".js-select-magazine");
    const epapersReturn = document.querySelector(".panel__epapers-return");
    let panelYearsClasses = "";
    if (panelEpaperYears) {
        panelYearsClasses = panelEpaperYears.className;
    }
    if (panelSelectMagazine) {
        panelSelectMagazine.addEventListener("click", function(e){
            if (e.target.classList.contains("panel__select-active")) {
                this.classList.toggle("select-open");
            }
            let list = panelSelectMagazine.querySelector(".js-panel-scroll");
            if (list) {
                const listScroll = new SimpleBar(list, {
                    autoHide: false,
                })
            }
        });
    }
    if (panelEpaper) {
        let iso = new Isotope(".panel__epapers",{
            itemSelector: ".panel__isotope-item",
            layoutMode: 'fitRows',
            hiddenStyle: {
                opacity: 0
            },
            visibleStyle: {
                opacity: 1
            }
        });

        let sliderEpapers = new Swiper ('.swiper-epapers.swiper-container', {
            slidesPerView: "auto",
            navigation: {
                nextEl: '.swiper-epapers .swiper-epapers__next',
                prevEl: '.swiper-epapers .swiper-epapers__prev',
            },
        });

        let sliderYears = new Swiper ('.swiper-years.swiper-container', {
            slidesPerView: "auto",
            navigation: {
                nextEl: '.swiper-years .swiper-epapers__next',
                prevEl: '.swiper-years .swiper-epapers__prev',
            },
        });

        function setYears(e) {
            if (e.target.dataset.hasOwnProperty("classes")) {
                panelEpaperYears.className = panelYearsClasses + " " + e.target.dataset.classes;
                let parent = document.querySelector(".panel__isotope-item[data-filter='" + e.target.dataset.filter + "']");
                let year = panelEpaperYears.querySelector("[data-year='" + e.target.dataset.start + "']");
                if (year) {
                    year.classList.add("year-active");
                }
                if (parent) {
                    parent.classList.add("year-" + e.target.dataset.start);
                }
                if (e.target.dataset.hasOwnProperty("count")) {
                    panelEpaperYears.dataset.count = e.target.dataset.count;
                }
                iso.layout();
            }
        }
        function switchYear(e) {
            let yearActive = document.querySelector(".year-active");
            if (yearActive) {
                yearActive.classList.remove("year-active");
            }
            e.target.classList.add("year-active");
            let element = document.querySelector(".open[data-filter]");
            switchYearClass(element, "year-" + e.target.dataset.year);
        }
        function switchYearClass(element, cssClass){
            if (element) {
                for(var i = 0; i < element.classList.length; i++) {
                    if(element.classList[i].indexOf("year") != -1) {
                        element.classList.remove(element.classList[i], i);
                    }
                }
                element.classList.add(cssClass);
            }
        }
        function sortMagazine(e) {
            let parent = document.querySelector(".panel__isotope-item[data-filter='" + e.target.dataset.filter + "']");
            let lazyLoadImgs = parent.querySelectorAll("[data-lazy-load]");
            for (var i = 0; i < lazyLoadImgs.length; i++) {
                lazyLoadImgs[i].addEventListener("load", function(e){
                    iso.layout();
                });
                let src = lazyLoadImgs[i].dataset.lazyLoad;
                lazyLoadImgs[i].src = src;
                lazyLoadImgs[i].attributes.removeNamedItem("data-lazy-load");
            }
            parent.classList.add("open");
            panelEpaper.parentElement.classList.add("selected");
            iso.arrange({filter: "[data-filter='" + e.target.dataset.filter + "']"});
        }
        function clearOpen() {
            let open = document.querySelector(".open");
            let active = document.querySelector(".active");
            let yearActive = document.querySelector(".year-active");
            panelEpaper.parentElement.classList.remove("selected");
            if (open) {
                open.classList.remove("open");
            }
            if (active) {
                active.classList.remove("active");
            }
            if (yearActive) {
                yearActive.classList.remove("year-active");
            }
        }
        panelEpaper.addEventListener("click", function(e){
            if (e.target.classList.contains("panel__btn-more")) {
                window.scrollTo(0,0);
                clearOpen();
                sortMagazine(e);
                setYears(e);
                sliderYears.update();
            }
        });

        if (panelEpaperMenu) {
            let activeTitle = panelEpaperMenu.querySelector(".panel__select-active");
            panelEpaperMenu.addEventListener("click", function(e){
                if (e.target.dataset.hasOwnProperty("filter")) {
                    clearOpen();
                    e.target.classList.add("active");
                    if (e.target.dataset.filter == "*") {
                        iso.arrange({filter: "*"});
                    }
                    else {
                        sortMagazine(e);
                    }
                    this.classList.remove("select-open");
                    setYears(e);
                    sliderYears.update();
                }
            });
        }
        if (epapersReturn) {
            epapersReturn.addEventListener("click", function(e){
                clearOpen();
                iso.arrange({filter: "*"});
                setYears(e);
            });
        }
        if (panelEpaperYears) {
            panelEpaperYears.addEventListener("click", function(e){
                if (e.target.dataset.hasOwnProperty("year")) {
                    switchYear(e);
                    iso.layout();
                }
            });
        }
        window.addEventListener("load", function(e){
            iso.layout();
        });
    }


    // Media
    const mediaList = document.querySelector(".panel-media__items");
    if (mediaList) {
        const yearsSelect = document.querySelector(".panel-media__years");
        mediaList.addEventListener("click", function(e){
            if (e.target.classList.contains("panel-media__btn-more")) {
                this.dataset.year= "all" ;
            }
        });
        if (yearsSelect) {
            const yearsSelected = yearsSelect.querySelector(".panel-media__years-selected");
            yearsSelect.addEventListener("click", function(e){
                if (e.target.classList.contains("panel-media__years-selected")) {
                    this.classList.toggle("open");
                }
                if (e.target.classList.contains("panel-media__years-item")) {
                    if (e.target.dataset.year == "all") {
                        yearsSelected.innerText = e.target.dataset.text;
                    }
                    else {
                        yearsSelected.innerText = e.target.dataset.year;
                    }
                    mediaList.dataset.year = e.target.dataset.year;
                }
            });
        }

    }
}
export default panelEpapers;
