const passReset = (XHRWrap) => {
    const resetBtn = document.querySelectorAll("[data-password='reset']");
    const loginModal = document.querySelector(".reset-wrap");
    const resetForm = document.querySelector("#pass-reset");
    const closeReset = document.querySelector(".modal-login__reset .c-close__bars");
    const message = document.querySelector("#pass-reset .checkout__form-message");
    const submitBtn = document.querySelector("#pass-reset input[type='submit']");
    const userName = document.querySelector("#pass-reset input[name='login']");
    let passwordReset;
    let reset = false;
    if (resetBtn.length > 0) {
        for (var i = 0; i < resetBtn.length; i++) {
            resetBtn[i].addEventListener("click", function(e){
                e.preventDefault();
                e.stopPropagation();
                loginModal.classList.add("reset");
            });
            closeReset.addEventListener("click", function(e){
                loginModal.classList.remove("reset");
            });
            resetForm.addEventListener("submit", function(e){
                e.preventDefault();
                e.stopPropagation();
                if (reset) {
                    return;
                }
                reset = true;
                submitBtn.value = "Wysyłanie";
                loginModal.classList.remove("error");
                loginModal.classList.remove("succes");
                message.classList.remove("error");
                message.classList.remove("succes");
                message.classList.remove("active");
                passwordReset = new XHRWrap({
                    url: "/user/reset-password",
                    form: resetForm,
                });
                passwordReset.send().then(
                    result => {
                        console.log(result);
                        if (result.result) {
                            message.querySelector("p").innerText = result.message;
                            userName.parentElement.style.display = "none";
                            message.classList.add("active");
                            message.classList.add("succes");
                            loginModal.classList.add("succes");
                            submitBtn.value = "Ok";
                            submitBtn.addEventListener("click", function(e){
                                e.preventDefault();
                                loginModal.classList.remove("reset");
                            });
                            reset = false;
                        }
                        else {
                            message.querySelector("p").innerText = result.message;
                            message.classList.add("active");
                            message.classList.add("error");
                            loginModal.classList.add("error");
                            submitBtn.value = "Dalej";
                            reset = false;
                        }
                    },
                    error => {
                        console.error(error);
                        message.querySelector("p").innerHTML = "<span class='title'>Ups! Coś poszło nie tak</span><span class='subtitle'>Prosimy spróbować ponownie za chwilę</span>";
                        message.classList.add("active");
                        message.classList.add("error");
                        loginModal.classList.add("error");
                        reset = false;
                        submitBtn.value = "Dalej";
                    }
                )
            });
        }
    }
}
export default passReset;
