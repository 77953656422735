const reader = (SimpleBar) => {
    if (typeof(readerJson) != "undefined") {
        const body = document.getElementsByTagName("body")[0];
        body.classList.add("reader-open");
        const data = readerJson;
        class Reader {
            constructor(settings = {}) {
                this.element = document.querySelector(".reader");
                this.data;
                this.customScroll;
                this.activeIndex = 0;
                this.hashChange = true;
                this.settings = {
                    zoom: document.querySelector(".reader").dataset.zoom === "true" ? true : false,
                    toc: false
                }
                this.state = {
                    slideChange: false,
                    zoomActive: document.querySelector(".reader").dataset.zoomActive === "true" ? true : false,
                };
                Object.assign(this, settings);
                this.init();
            }
            setElements() {
                this.tocBtn = this.element.querySelector("[data-reader='toc-btn']");
                this.itemView = this.element.querySelector("[data-reader='view']");
                this.tocList = this.element.querySelector("[data-reader='toc-list']");
                this.tocContent = this.element.querySelector("[data-reader='toc-content']");
                this.imageActive = this.element.querySelector("[data-reader='image-active']");
                this.countTotal = this.element.querySelector("[data-reader='count-total']");
                this.countActive = this.element.querySelector("[data-reader='count-active']");
                this.imageNextBtn = this.element.querySelector("[data-reader='next']");
                this.imagePrevBtn = this.element.querySelector("[data-reader='prev']");
                this.itemTitle = this.element.querySelector("[data-reader='title']");
                this.itemDate = this.element.querySelector("[data-reader='date']");
                this.zoomBtn = this.element.querySelector("[data-reader='zoom']")
            }
            setImageLoadEvent() {
                const self = this;
                this.imageActive.addEventListener("load", function(e){
                    self.element.dataset.imageLoading = "false";
                });
            }
            setClick() {
                const self = this;
                this.element.addEventListener("click", function(e){
                    const targetDataset = e.target.dataset.reader;
                    if (self.tocBtn && self.tocBtn.dataset.reader == targetDataset) {
                        e.preventDefault();
                        e.stopPropagation();
                        self.tocBtn.classList.toggle("open");
                        self.tocList.classList.toggle("open");
                    }
                    if (self.imageNextBtn && self.imageNextBtn.dataset.reader == targetDataset) {
                        e.preventDefault();
                        e.stopPropagation();
                        self.loadImage(self.activeIndex + 1, self.imageActive);
                        self.setHash(self.activeIndex + 1);
                    }
                    if (self.imagePrevBtn && self.imagePrevBtn.dataset.reader == targetDataset) {
                        e.preventDefault();
                        e.stopPropagation();
                        self.loadImage(self.activeIndex - 1, self.imageActive);
                        self.setHash(self.activeIndex + 1);
                    }
                    if (self.zoomBtn && self.zoomBtn.dataset.reader == targetDataset) {
                        if (self.state.zoomActive) {
                            self.state.zoomActive = false;
                            self.element.dataset.zoomActive = "false";
                        }
                        else {
                            self.state.zoomActive = true;
                            self.element.dataset.zoomActive = "true";
                        }
                        self.loadImage(self.activeIndex, self.imageActive);
                    }
                });
            }
            loadImage(index, image) {
                this.element.dataset.change = "true";
                this.element.dataset.imageLoading = "true";
                let newIndex = index;
                if (newIndex < 0) {
                    newIndex = this.data.images.length - 1;
                }
                if (newIndex > this.data.images.length - 1) {
                    newIndex = 0;
                }
                if (this.state.zoomActive) {
                    image.src = this.data.images[newIndex].lg;
                }
                else {
                    image.src = this.data.images[newIndex].sm;
                }
                this.activeIndex = newIndex;
                this.countActive.innerText = newIndex + 1;
                this.element.dataset.change = "false";
                if (image.complete) {
                    this.element.dataset.imageLoading = "false";
                }
            }
            loadData() {
                if (this.data.hasOwnProperty("tocActive")) {
                    this.settings.toc = this.data.tocActive;
                }
                if (this.data.hasOwnProperty("toc") && this.settings.toc) {
                    this.tocContent.innerHTML = this.data.toc;
                    if (this.customScroll) {
                        const tocScroll = new SimpleBar(this.tocContent, {
                            autoHide: false,
                        });
                    }
                }
                if (this.data.hasOwnProperty("images") && this.data.images.length > 0) {
                    this.countTotal.innerText = this.data.images.length;
                    this.countActive.innerText = this.activeIndex + 1;
                    if (this.state.zoomActive) {
                        this.imageActive.src = this.data.images[this.activeIndex].lg;
                    }
                    else {
                        this.imageActive.src = this.data.images[this.activeIndex].sm;
                    }
                }
                if (this.data.hasOwnProperty("title") && this.itemTitle) {
                    this.itemTitle.innerHTML = this.data.title;
                }
                if (this.data.hasOwnProperty("date") && this.itemDate) {
                    this.itemDate.innerText = this.data.date;
                }
                if (this.data.hasOwnProperty("demoZoom")) {
                    this.settings.zoom = this.data.demoZoom;
                }

            }
            checkUrl() {
                const self = this;
                let index = parseInt(document.location.hash.substring(1));
                if (!isNaN(index) && document.location.hash != "") {
                    if (index > self.data.images.length) {
                        index = self.data.images.length;
                    }
                    else if (index < 1) {
                        index = 1;
                    }
                    self.loadImage(index - 1, self.imageActive);
                    self.setHash(index);
                }
                else if (document.location.hash == "") {
                    document.location.hash = 1;
                }
            }
            watchUrl() {
                const self = this;
                window.addEventListener("hashchange", function(e){
                    e.preventDefault();
                    if (!self.state.slideChange) {
                        let index = parseInt(document.location.hash.substring(1));
                        if (!isNaN(index) && document.location.hash != "") {
                            if (index > self.data.images.length) {
                                index = self.data.images.length;
                            }
                            else if (index < 1) {
                                index = 1;
                            }
                            self.loadImage(index - 1, self.imageActive);
                            self.setHash(index);
                        }
                    }
                    self.state.slideChange = false;
                });
            }
            setKeyboardsEvents() {
                const self = this;
                document.addEventListener("keydown", function(e){
                    switch (e.keyCode) {
                        case 39:
                            self.state.slideChange = true;
                            self.loadImage(self.activeIndex + 1, self.imageActive);
                            self.setHash(self.activeIndex + 1);
                            break;
                        case 37:
                            self.state.slideChange = true;
                            self.loadImage(self.activeIndex - 1, self.imageActive);
                            self.setHash(self.activeIndex + 1);
                            break;
                        default:
                    }
                });
            }
            setHash(index) {
                document.location.hash = index;
            }
            checkInit() {
                if (this.data.images.length <= 0) {
                    console.error("Images array is empty");
                    return false;
                }
                else {
                    return true;
                }
            }
            init() {
                if (this.checkInit()) {
                    this.setElements();
                    this.setClick();
                    this.checkUrl();
                    this.loadData();
                    this.setKeyboardsEvents();
                    if (this.hashChange) {
                        this.watchUrl();
                    }
                    this.setImageLoadEvent();
                }
            }
        }
        const reader = new Reader({
            data: readerJson,
            customScroll: true,
        });
    }
}
export default reader;
