const panelHistory = () => {
    const historyItems = document.querySelector(".panel__history-items");
    if (historyItems) {
        historyItems.addEventListener("click", function(e){
            if (e.target.classList.contains("history-item__toggle")) {
                const parent = e.target.closest(".history-item");
                parent.classList.toggle("open");
            }
        });
    }
}
export default panelHistory;
