const accountForms = (XHRWrap, Simplebar) => {
    const accountForms = document.querySelectorAll(".panel__account-forms form");
    const loader = document.querySelector(".checkout__loader");
    const errorMessage = document.querySelector(".basket-add--error");
    const successMessage = document.querySelector(".basket-add--success");
    const basketAddClose = document.querySelectorAll("[data-basket-box='close']");
    if (accountForms.length > 0) {
        if (basketAddClose.length > 0) {
            for (var i = 0; i < basketAddClose.length; i++) {
                basketAddClose[i].addEventListener("click", function(e){
                    e.preventDefault();
                    e.stopPropagation();
                    errorMessage.classList.remove("open");
                    successMessage.classList.remove("open");
                });
            }
        }
        for (var i = 0; i < accountForms.length; i++) {
            accountForms[i].addEventListener("change", function(e){
                if (e.target.value != "") {
                    e.target.nextElementSibling.classList.add("active");
                }
                else {
                    e.target.nextElementSibling.classList.remove("active");
                }
            });
            accountForms[i].addEventListener("submit", function(e){
                e.preventDefault();
                if (loader) {
                    loader.parentElement.classList.add("sending");
                }
                const self = this;
                let xhr = new XHRWrap({
                    url: self.dataset.url,
                    form: self
                });
                xhr.send().then(
                    result => {
                        successMessage.querySelector(".basket-add__error-message").innerText = result.message;
                        successMessage.classList.add("open");
                        loader.parentElement.classList.remove("sending");
                    },
                    error => {
                        errorMessage.querySelector(".basket-add__error-message").innerText = result.message;
                        errorMessage.classList.add("open");
                        loader.parentElement.classList.remove("sending");
                    }
                )
            });
        }
    }
    const promoCodes = document.querySelector(".promo-codes__scroll-wrap");
    if (promoCodes) {
        const promoCodesScroll = new Simplebar(promoCodes, {
            autoHide: false,
        })
    }
}

export default accountForms;
