const login = (XHRWrap) => {
    const loginForm = document.querySelector("#login-form");
    if (loginForm) {
        const formWrap = document.querySelector(".login-form");
        const formMessage = formWrap.querySelector(".login-form__message");
        const loginBtn = loginForm.querySelector("[type='submit']");
        const loginInputs = loginForm.querySelectorAll(".js-login-input");
        for (var i = 0; i < loginInputs.length; i++) {
            if (loginInputs[i].value != "") {
                loginInputs[i].classList.add("filled");
            }
        }
        let login = new XHRWrap({
            url: "/user/login",
            form: loginForm,
            additionalFormData: {
                target: "uk",
            }
        });
        let logout = new XHRWrap({
            url: "/user/logout",
            type: "GET"
        });
        loginForm.addEventListener("submit", function(e){
            e.preventDefault();
            login.send().then(
                result => {
                    console.log(result);
                    formWrap.classList.add("message");
                    formMessage.innerText = result.message;
                    if (result.result) {
                        formMessage.classList.remove("error");
                        formMessage.classList.add("succes");
                        if (document.URL.indexOf("aktywacja-konta") != -1) {
                            window.location.replace(document.location.origin);
                        }
                        else {
                            window.location.reload();
                        }
                    }
                    else {
                        formMessage.classList.remove("succes");
                        formMessage.classList.add("error");
                    }
                },
                error => {
                    console.error(error);
                    formWrap.classList.add("message");
                    formMessage.classList.remove("succes");
                    formMessage.classList.add("error");
                    formMessage.innerText = "Wystąpił wewnętrzny błąd. Spróbuj ponownie za chwilę";
                }
            )
        });
    }

    const errorMessage = document.querySelector(".basket-add--error");
    document.addEventListener("click", function(e){
        if (e.target.dataset.logout == "true") {
            e.preventDefault();
            e.stopPropagation();
            let logout = new XHRWrap({
                url: "/user/logout",
                type: "GET"
            });
            logout.send().then(
                result => {
                    if (document.URL.indexOf("aktywacja-konta") != -1) {
                        window.location.replace(document.location.origin);
                    }
                    else {
                        window.location.reload();
                    }
                },
                error => {
                    errorMessage.classList.add("open");
                }
            )
        }
    });


    const repeatEmail = document.getElementById("email2");
    if (repeatEmail) {
        repeatEmail.addEventListener("paste", function(e){
            e.preventDefault();
        });
    }
}
export default login;
