import SimpleBar from 'simplebar';
import 'bootstrap.native/dist/polyfill';
import 'bootstrap.native/dist/bootstrap-native-v4';
import Swiper from 'swiper';
import Isotope from 'isotope-layout';
import SocialAuth from "./components/SocialAuth";

import sidebar from './components/sidebar';
import sliders from './components/sliders';
import order from './components/order'
import widgetBasket from "./components/widgets/basket";
import stickyHeader from "./components/sticky-header";
import freeShipping from "./components/widgets/free-shipping";
import cookies from "./components/cookies";
import viewSwitch from "./components/viewSwitch";
import xhrwrap from "./components/xhrWrap";
import login from "./components/login";
import contact from "./components/contact";
import passReset from "./components/passReset";
import folder from "./components/folder";
import reader from "./components/reader";
import subscriptionList from "./components/subscription-list";
import fbPixel from "./components/facebook-pixel";

// Pages
import newsletterUnsubscribe from "./pages/newsletter-unsubscribe";
import partners from "./pages/partners";
import loginPage from "./pages/login";

// panel
import panelEpapers from "./panel/epapers";
import panelSubscription from "./panel/subscription";
import accountForms from "./panel/account";
import newPanelInfo from "./panel/newPanelInfo";
import panelHistory from "./panel/history";

// widgets
import promoTop from "./components/widgets/promo-top";

const XHRWrap = xhrwrap();
const docCookies = cookies();
document.addEventListener('DOMContentLoaded', function() {
    fbPixel();
    sidebar();
    sliders(Swiper);
    order(XHRWrap, docCookies);
    widgetBasket(SimpleBar);
    stickyHeader();
    freeShipping(docCookies);
    viewSwitch(docCookies);
    newPanelInfo(docCookies);
    login(XHRWrap);
    contact(XHRWrap);
    passReset(XHRWrap);
    reader(SimpleBar);
    partners(XHRWrap);
    subscriptionList();
    panelEpapers(Isotope, Swiper, SimpleBar);
    panelSubscription(XHRWrap);
    accountForms(XHRWrap, SimpleBar);
    panelHistory();
    newsletterUnsubscribe(XHRWrap);
    promoTop();
    loginPage();

    if (typeof(socialAuthConfig) !== "undefined") {
        const socialAuthorization = new SocialAuth(socialAuthConfig);
        socialAuthorization.init();
    }
    /*--- find toggle ---*/
    const findToggleElem = document.querySelectorAll('.js-find-toggle'),
        findElem = document.querySelector('.c-find'),
        bodyElem = document.querySelector('body'),
        headerElem = document.querySelector('header');

    for(let i = 0; i < findToggleElem.length; i++) {
        findToggleElem[i].addEventListener('click', findToggle);
    };

    const userMenu = document.querySelector(".header__user");
    if (userMenu) {
        userMenu.addEventListener("click", function(e){
            this.classList.toggle("open");
        });
    }
    function closeBody() {
        var bodyWidth =  bodyElem.offsetWidth;
        bodyElem.classList.add('close');
        var bodyWidthClose = bodyElem.offsetWidth;

        if (bodyWidthClose > bodyWidth) {
            bodyElem.style.paddingRight = bodyWidthClose - bodyWidth + 'px';
            headerElem.style.paddingRight = bodyWidthClose - bodyWidth + 'px';
        }
    }

    function openBody() {
        bodyElem.classList.remove('close');
        bodyElem.style.paddingRight = '';
        headerElem.style.paddingRight = '';
    }

    function findToggle(e) {
        e.preventDefault();
        if (findElem.classList.contains('is-show')) {
            findElem.classList.remove('changing');
            setTimeout(() => {
                findElem.classList.remove('is-show');
                openBody();
            }, 450);
        } else {
            findElem.classList.add('is-show');
            findElem.offsetWidth; // force reflow to enable transition
            findElem.classList.add('changing');
            closeBody();
        }
    };
    /*--- find toggle End ---*/

    /*--- Login input ---*/
    const loginInputs = document.querySelectorAll('.js-login-input');
    for (let i = 0; i < loginInputs.length; i++) {
        loginInputs[i].addEventListener('blur', function() {
            if (this.value !== '') {
                this.classList.add('filled');
            } else {
                this.classList.remove('filled');
            }
        });
    }
    /*--- Login input End ---*/

    /*--- animation background ---*/
    const animBgUlElem = document.querySelectorAll('.c-anim-bg-ul a');

    for (var i = 0; i < animBgUlElem.length; i++) {
        animBgUlElem[i].addEventListener('mousedown', function (event) {
            const elemBg = this.querySelector('.abg');

            elemBg.classList.remove('is-pressed');
            elemBg.style.left = getBgPos(event, this).x + 'px';
            elemBg.style.top = getBgPos(event, this).y + 'px';
            elemBg.classList.add('is-pressed');
        }, false);
    }

    const getBgPos = (event, elem) => {
        return {
            x: event.clientX - elem.getBoundingClientRect().left,
            y: event.clientY - elem.getBoundingClientRect().top
        }
    }
    /*--- animation background End ---*/

    /* --- Change header --- */
    const changeElem = document.querySelector('[data-change]');

    if (changeElem) {
        let scroll, changePos, resizeTimeout;
        const headerElem = document.querySelector('header');

        changePos = changeElem.getBoundingClientRect().top + (window.pageYOffset || document.documentElement.scrollTop) + changeElem.offsetHeight - 70;

        const changeHeader = () => {
            if (headerElem && changePos) {
                scroll = window.pageYOffset || document.documentElement.scrollTop;

                if (scroll >= changePos) {
                    headerElem.classList.remove('header--black');
                } else {
                    headerElem.classList.add('header--black');
                }
            }
        };

        window.addEventListener('scroll', () => {
            changeHeader();
        });

        window.addEventListener("resize", () => {
            if ( !resizeTimeout ) {
                resizeTimeout = setTimeout(function() {
                    resizeTimeout = null;

                    changePos = changeElem.getBoundingClientRect().top + (window.pageYOffset || document.documentElement.scrollTop) + changeElem.offsetHeight - 70;
                    changeHeader();
                    // The actualResizeHandler will execute at a rate of 15fps
                }, 66);
            }
        }, false);

        changeHeader();
    }
    /* --- Change header --- End */

    if (document.getElementById("js-find-scroll")) {
        new SimpleBar(document.getElementById('js-find-scroll'), {autoHide: false});
    }

    if (document.getElementById("js-club-scroll")) {
        new SimpleBar(document.getElementById('js-club-scroll'), {autoHide: false});
    }

    /* --- product pakiety --- */
    var pakiet1 = document.getElementById('pakiet-1');
    if (pakiet1) {
        var pakiet2 = document.getElementById('pakiet-2');

        pakiet1.addEventListener('show.bs.collapse', function () {
            this.classList.add('start');
        });
        pakiet1.addEventListener('hide.bs.collapse', function () {
            this.classList.remove('start');
        });

        pakiet2.addEventListener('show.bs.collapse', function () {
            this.classList.add('start');
        });
        pakiet2.addEventListener('hide.bs.collapse', function () {
            this.classList.remove('start');
        });
    }
    /* --- product pakiety End --- */


    /* --- Messages --- */
    const closeMessages = document.querySelector('.js-close-messages');
    const messages = document.querySelector('.c-messages');

    function messagesAnimationEnd() {
        this.style.display = 'none';
    }

    if (closeMessages) {
        closeMessages.addEventListener('click', function() {
            messages.classList.add('is-closed');
        });
    }

    if (messages) {
        messages.addEventListener('webkitAnimationEnd', messagesAnimationEnd);
        messages.addEventListener('oanimationend', messagesAnimationEnd);
        messages.addEventListener('oAnimationEnd', messagesAnimationEnd);
        messages.addEventListener('msAnimationEnd', messagesAnimationEnd);
        messages.addEventListener('animationend', messagesAnimationEnd);
    }
    /* --- Messages End --- */

    /* --- Messages slide --- */
    const messagesSlide = document.querySelector('.js-slide');
    const pushInput = document.querySelector('input#push');
    const emailInput = document.querySelector('input#e-mail');

    if (messagesSlide) {
        messagesSlide.addEventListener('click', function() {
            if (pushInput.checked) {
                pushInput.checked = false;
                emailInput.checked = true;
            } else {
                pushInput.checked = true;
                emailInput.checked = false;
            }
        });
    }
    /* --- Messages slide End --- */

    // Regulations detect slide moment - START

    const links = document.querySelectorAll('.regulations__list-item');
    const sections = document.querySelectorAll('.regulations__header');

    if(links.length > 0) {
        function changeLinkState() {
            let index = sections.length;

            while(--index && window.scrollY + 50 < sections[index].offsetTop) {}
            links.forEach((link) => link.classList.remove('active'));
            links[index].classList.add('active');
        }

        changeLinkState();
        for(let i = 0; i < links.length; i++) {
            links[i].addEventListener('click', function(event){
                for(let j =0; j < sections.length; j++) {
                    if(sections[j].classList.contains(this.dataset.active)) {
                        window.scroll({
                            top : sections[j].offsetTop + 103,
                            left : 0,
                            behavior : 'smooth'
                        });
                    }
                }
            })
        }
        window.addEventListener('scroll', changeLinkState);
    }

    // Regulations detect slide moment - END


    /* --- Order range slider --- */

    var rangeSlider = document.getElementById("range-slider");

    if (rangeSlider) {
        /*var output = document.getElementById("demo");
        output.innerHTML = slider.value;*/

        var rangeYears = document.getElementById("range-year");
        var rangeNum = document.getElementById("range-num");
        var rangeValue;

        var rangeYearsData = [
            '1 rok',
            '2 lata',
            '3 lata',
            '4 lata',
            '5 lat'
        ];

        var rangeNumData = [
            '(12 wydań)',
            '(24 wydania)',
            '(36 wydań)',
            '(48 wydań)',
            '(60 wydań)'
        ];

        function inputChange() {
            rangeValue = parseInt(rangeSlider.value, 10) - 1;

            var name = 'Some name';
            rangeYears.innerHTML = rangeYearsData[rangeValue];
            rangeNum.innerHTML = rangeNumData[rangeValue];
        }

        rangeSlider.onchange = function() {
            if(/MSIE \d|Trident.*rv:/.test(navigator.userAgent)) {
                inputChange();
            }
        }

        rangeSlider.oninput = function() {
            if(!/MSIE \d|Trident.*rv:/.test(navigator.userAgent)) {
                inputChange();
            }
        }
    }
    /* --- Order range slider End --- */

    /* Archive list year switch */
    const archiveList = document.querySelector(".grid-list[data-year]");
    const archiveNav = document.querySelector(".archive-list__ul");
    if (archiveList && archiveNav) {
        const archiveBtns = document.querySelectorAll("li[data-year]");
        archiveNav.addEventListener("click", function(e){
            if (typeof(e.target.dataset.year) != "undefined") {
                e.preventDefault();
                for (var i = 0; i < archiveBtns.length; i++) {
                    let btn = archiveBtns[i].querySelector(".abg");
                    btn.classList.remove("is-pressed");
                }
                e.target.querySelector(".abg").classList.add("is-pressed");
                archiveList.dataset.year = e.target.dataset.year;
            }
        });
    }
    /* Archive list year switch end */

    /* Finder */
    const finder = document.querySelector(".c-find");
    Array.prototype.insert = function ( index, item ) {
        this.splice( index, 0, item );
    };
    if (finder) {
        const finderForm = finder.querySelector(".c-find__form");
        const finderItems = finder.querySelectorAll(".c-find__item-link");
        if (finderForm && finderItems.length > 0) {
            const formInput = finder.querySelector(".c-find__form-input");
            function findElements() {
                let find = false;
                finder.classList.remove("no-result");
                if (formInput.value.length > 1) {
                    finder.classList.add("finding");
                    let searchText = formInput.value.trim().toLowerCase();
                    for (let i = 0; i < finderItems.length; i++) {
                        let elementText = finderItems[i].textContent.trim().toLowerCase();
                        if (elementText.indexOf(searchText) != -1) {
                            find = true;
                            finderItems[i].classList.add("active");
                            let txt = finderItems[i].textContent.trim().toLowerCase();
                            let startIndex = txt.indexOf(searchText);
                            let endIndex = txt.indexOf(searchText) + searchText.length + 1;
                            txt = finderItems[i].innerText.trim().split("");
                            txt.insert(startIndex, "<span>");
                            txt.insert(endIndex, "</span>");
                            txt = txt.join("");

                            finderItems[i].innerHTML = txt;
                        }
                        else {
                            finderItems[i].classList.remove("active");
                        }
                    };
                    if (!find) {
                        finder.classList.remove("finding");
                        finder.classList.add("no-result");
                        for (let i = 0; i < finderItems.length; i++) {
                            finderItems[i].classList.remove("active");
                            finderItems[i].innerHTML = finderItems[i].dataset.text;
                        }
                        find = false;
                    }
                }
                else {
                    finder.classList.remove("finding");
                    finder.classList.remove("no-result");
                    for (let i = 0; i < finderItems.length; i++) {
                        finderItems[i].classList.remove("active");
                        finderItems[i].innerHTML = finderItems[i].dataset.text;
                    }
                    find = false;
                };
            }
            finderForm.addEventListener("submit", function(e){
                e.preventDefault();
            });
            finderForm.addEventListener("click", function(e){
                e.stopPropagation();
            });
            formInput.addEventListener("keypress", function(){
                findElements();
            });
            formInput.addEventListener("keydown", function(){
                findElements();
            });
            formInput.addEventListener("keyup", function(){
                findElements();
            });
        }
    }
    /* Finder end */

    // Product single set height and position - START

    var topContainer = document.getElementsByClassName('product-article__top')[0];
    var backgroundContainer = document.querySelector('.product-article .background-container');

    if(topContainer) {
        const setHeightInterval = setInterval(() => {
            setSingleTopContentHeight();
        }, 100);
        window.addEventListener('load',function(){
            clearInterval(setHeightInterval);
            setSingleTopContentHeight();
        })
        window.addEventListener('resize',setSingleTopContentHeight);
    }

    function setSingleTopContentHeight() {
        var topHeight = topContainer.getBoundingClientRect().height;
        var topOffset = topContainer.getBoundingClientRect().top;

        backgroundContainer.style.height = (topHeight + 100) + 'px';
    }

    // Module inside - START

    var moduleInsideProduct = document.getElementsByClassName('product-article__next-number-background')[0];
    var leftOffsetContainer = document.getElementsByClassName('left-offset-calc')[0];

    if(moduleInsideProduct) {
        var leftOffset = moduleInsideProduct.getBoundingClientRect().left;
        const setPositionInterval = setInterval(() => {
            moduleInsideProduct.style.left = '-' + leftOffset + 'px';
            moduleInsideProduct.style.width = backgroundContainer.getBoundingClientRect().width + 'px';
        }, 100);
        window.addEventListener('load',function(){
            clearInterval(setPositionInterval);

            moduleInsideProduct.style.left = '-' + leftOffset + 'px';
            moduleInsideProduct.style.width = backgroundContainer.getBoundingClientRect().width + 'px';
        })
        window.addEventListener('resize',changeModuleSize);
    }


    function changeModuleSize() {
        moduleInsideProduct.style.left = '-' + (leftOffsetContainer.getBoundingClientRect().left + 15) + 'px';
        moduleInsideProduct.style.width = backgroundContainer.getBoundingClientRect().width + 'px';
    }
    // Module inside - END

    // Product single set height and position - END

}, false);
