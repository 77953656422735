const newsletterUnsubscribe = (XHRWrap) => {
    const unsubscribe = document.querySelector(".newsletter-unsubscribe");
    if (unsubscribe) {
        const unsubscribeReasonWrap = unsubscribe.querySelector(".newsletter-unsubscribe__reason-wrap");
        const unsubscribeReasonTitle = unsubscribe.querySelector(".newsletter-unsubscribe__reason-title");
        const unsubscribeReasonList = unsubscribe.querySelector(".newsletter-unsubscribe__reason-list");
        const unsubscribeReasonInput = unsubscribe.querySelector(".newsletter-unsubscribe__input-reason");
        const unsubscribeForm = unsubscribe.querySelector(".newsletter-unsubscribe__form");
        const formMessage = unsubscribe.querySelector(".checkout__form-message");
        const errorPopup = document.querySelector(".basket-add--error");
        if (unsubscribeReasonTitle && unsubscribeReasonWrap) {
            unsubscribeReasonTitle.addEventListener("click", function(e){
                unsubscribeReasonWrap.classList.toggle("open");
            });
        }
        if (unsubscribeReasonList && unsubscribeReasonInput && unsubscribeReasonWrap && unsubscribeReasonTitle && unsubscribeReasonWrap) {
            const unsubscribeReasonSelected = unsubscribeReasonTitle.querySelector(".selected");
            unsubscribeReasonList.addEventListener("click", function(e){
                if (e.target.dataset.hasOwnProperty("value")) {
                    if (unsubscribeReasonInput.value == e.target.dataset.value) {
                        unsubscribeReasonWrap.classList.remove("selected");
                        unsubscribeReasonSelected.innerText = "";
                        unsubscribeReasonInput.value = "";
                        for (var i = 0; i < this.children.length; i++) {
                            this.children[i].classList.remove("selected");
                        }
                    }
                    else {
                        for (var i = 0; i < this.children.length; i++) {
                            this.children[i].classList.remove("selected");
                        }
                        e.target.classList.add("selected");
                        unsubscribeReasonWrap.classList.add("selected");
                        unsubscribeReasonSelected.innerText = e.target.innerText;
                        unsubscribeReasonInput.value = e.target.dataset.value;
                    }
                    unsubscribeReasonWrap.classList.remove("open");
                    if (unsubscribeForm) {
                        unsubscribeForm.classList.remove("validate");
                    }
                }
            })
        }
        if (unsubscribeForm) {
            unsubscribeForm.addEventListener("submit", function(e){
                e.preventDefault();
                if (unsubscribeReasonInput && unsubscribeReasonInput.value == "") {
                    this.classList.add("validate");
                    return;
                }
                this.classList.add("sending");
                if (formMessage) {
                    formMessage.classList.remove("active");
                }
                const unsubscribeXHR = new XHRWrap({
                    url: "/newsletter",
                    form: this,
                });
                unsubscribeXHR.send().then(
                    result => {
                        if (result.result == false) {
                            if (formMessage) {
                                formMessage.querySelector("p").innerText = result.message;
                                formMessage.classList.add("error");
                                formMessage.classList.add("active");
                            }
                        }
                        else {
                            if (formMessage) {
                                formMessage.querySelector("p").innerText = result.message;
                                formMessage.classList.add("succes");
                                formMessage.classList.add("active");
                            }
                        }
                        this.classList.remove("sending");

                    },
                    error => {
                        this.classList.remove("sending");
                        if (errorPopup) {
                            errorPopup.classList.add("open");
                        }
                        console.log(error);
                    }
                );
            });
        }
    }
}
export default newsletterUnsubscribe;
