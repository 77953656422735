const sidebar = () => {
    /* --- Menu --- */
var menuToggleElem = document.querySelector('.js-sidebar-toggle'),
    menuCloseElem = document.querySelector('.js-sidebar-close'),
    siteMenuElem = document.querySelector('.c-sidebar'),
    siteCloseElem = document.querySelector('.site-close'),
    //siteCanvasElem = document.querySelector('.site-canvas'),
    headerElem = document.querySelector('header'),
    bodyElem = document.querySelector('body'),
    menuStatus = "closed",
    menuWidth,
    timeToggle = 0.45,
    moved,
    excluded = ['.kiosk', '.owl-carousel', '.swiper-container'];

function menuToggle() {
    menuWidth = siteMenuElem.offsetWidth;

    setDuration(siteMenuElem, timeToggle);
    //setDuration(siteCanvasElem, timeToggle);
    setDuration(siteCloseElem, timeToggle);

    if (menuToggleElem.classList.contains('is-fire')) {
        hideMenu();

        afterHide(timeToggle);

    } else {
        siteCloseElem.style.display = 'block';

        if (document.querySelector('.custom.float')) {
            document.querySelector('.custom.float').style.display = 'none';
        }

        showMenu();
    }
}

function hideMenu() {
    menuToggleElem.classList.remove('is-fire');

    openBody();

    setTranslateX(siteMenuElem, '0%');
    //setTranslateX(siteCanvasElem, 0);

    siteCloseElem.style.opacity = 0;

    menuStatus = "closed";

    currentPosX = 0;
}

function showMenu() {
    closeBody();
    clearTimeout(moved);

    menuToggleElem.classList.add('is-fire');

    setTranslateX(siteMenuElem, '100%');
    //setTranslateX(siteCanvasElem, menuWidth/3);

    siteCloseElem.style.opacity = 1;

    menuStatus = "opened";

    currentPosX = menuWidth;
}

function afterHide(time) {
    moved = setTimeout(function() {
        siteCloseElem.style.display = '';
    }, time*1000);
}

function closeBody() {
    var bodyWidth =  bodyElem.offsetWidth;
    bodyElem.classList.add('close');
    var bodyWidthClose = bodyElem.offsetWidth;

    if (bodyWidthClose > bodyWidth) {
        bodyElem.style.paddingRight = bodyWidthClose - bodyWidth + 'px';
        headerElem.style.paddingRight = bodyWidthClose - bodyWidth + 'px';
    }
}

function openBody() {
    bodyElem.classList.remove('close');
    bodyElem.style.paddingRight = '';
    headerElem.style.paddingRight = '';
}

function setTranslateX(elem, num) {
    elem.style.MozTransform = 'translateX('+num+')';
    elem.style.MsTransform = 'translateX('+num+')';
    elem.style.WebkitTransform = 'translateX('+num+')';
    elem.style.OTransform = 'translateX('+num+')';
    elem.style.transform = 'translateX('+num+')';
}

function setDuration(elem, time) {
    elem.style.MozTransitionDuration = time +'s';
    elem.style.MsTransitionDuration = time +'s';
    elem.style.WebkitTransitionDuration = time +'s';
    elem.style.OTransitionDuration = time +'s';
    elem.style.transitionDuration = time +'s';
}

function removeTranslateX(elem) {
    elem.style.MozTransform = '';
    elem.style.MsTransform = '';
    elem.style.WebkitTransform = '';
    elem.style.OTransform = '';
    elem.style.transform = '';
}

if (menuToggleElem) {
    menuToggleElem.addEventListener('click', menuToggle);
}

if (siteCloseElem) {
    siteCloseElem.addEventListener('click', function() {
        menuWidth = siteMenuElem.offsetWidth;

        setDuration(siteMenuElem, timeToggle);
        setDuration(siteCloseElem, timeToggle);

        hideMenu();

        afterHide(timeToggle);
    });
}

if (menuCloseElem) {
    menuCloseElem.addEventListener('click', function() {
        menuWidth = siteMenuElem.offsetWidth;

        setDuration(siteMenuElem, timeToggle);
        setDuration(siteCloseElem, timeToggle);

        hideMenu();

        afterHide(timeToggle);
    });
}

/* swipe Menu */
var startTouchX,
    startTouchY,
    newPosX,
    newPosY,
    lastPosX,
    currentPosX = 0,
    moveDelta = 8,
    changePoint = 60,
    onesBigest,
    sliding,
    firedOnce,
    direction = null;

var hasTouchStartEvent = 'ontouchstart' in document.createElement( 'div' );

var touchEvent = ["touchstart", "touchmove", "touchend"],
    mouseEvent = ["mousedown", "mousemove", "mouseup"],
    evStart, evMove, evEnd;

var startElement;

if (hasTouchStartEvent) {
    evStart = touchEvent[0];
    evMove = touchEvent[1];
    evEnd = touchEvent[2];
} else {
    evStart = mouseEvent[0];
    evMove = mouseEvent[1];
    evEnd = mouseEvent[2];
}

function getTouches(event) {
    if (event.touches !== undefined) {
        return {
            x : event.touches[0].pageX,
            y : event.touches[0].pageY
        };
    }

    if (event.touches === undefined) {
        if (event.pageX !== undefined) {
            return {
                x : event.pageX,
                y : event.pageY
            };
        }
        if (event.pageX === undefined) {
            return {
                x : event.clientX,
                y : event.clientY
            };
        }
    }
}

function swapEvents(type) {
    if (type === "on") {
        document.addEventListener(evMove, dragMove, false);
        document.addEventListener(evEnd, dragEnd, false);
    } else if (type === "off") {
        document.removeEventListener(evMove, dragMove);
        document.removeEventListener(evEnd, dragEnd);
    }
}

function closest(el, selector) {
    var matchesFn;

    /* find vendor prefix */
    ['matches','webkitMatchesSelector','mozMatchesSelector','msMatchesSelector','oMatchesSelector'].some(function(fn) {
        if (typeof document.body[fn] === 'function') {
            matchesFn = fn;
            return true;
        }
        return false;
    });

    var parent;

    /* traverse parents */
    while (el) {
        parent = el.parentElement;
        if (parent && parent[matchesFn](selector)) {
            return parent;
        }
        el = parent;
    }

    return null;
}

function dragStart(event) {
    var ev = event.originalEvent || event || window.event;

    ev.target = ev.target || ev.srcElement;

    var exl = function() {
        for(var i=0; i<excluded.length; i++) {
            if (closest(ev.target, excluded[i])) {return false; break;}
        }

        return true;
    };

    if (!exl()) return;

    swapEvents("on");

    document.addEventListener('dragstart', function(event) { event.preventDefault(); }, false);

    startElement = ev.target;

    startTouchX = getTouches(ev).x;
    startTouchY = getTouches(ev).y;

    menuWidth = siteMenuElem.offsetWidth;

    onesBigest = false;
    sliding = false;
    firedOnce = false;

    lastPosX = newPosX = 0;
}

function dragMove(event) {
    var ev = event.originalEvent || event || window.event;

    newPosX = getTouches(ev).x - startTouchX;
    newPosY = getTouches(ev).y - startTouchY;

    if (Math.abs(newPosY) > moveDelta && sliding === false) { swapEvents("off"); return; }

    if (Math.abs(newPosX) > moveDelta) {
        onesBigest = true;
        sliding = true;

        if (!firedOnce) {
            firedOnce = true;

            setDuration(siteMenuElem, 0);
            //setDuration(siteCanvasElem, 0);
            setDuration(siteCloseElem, 0);

            clearTimeout(moved);

            siteCloseElem.style.display = 'block';

            if (document.querySelector('.custom.float')) {
                document.querySelector('.custom.float').style.display = 'none';
            }
        }


        if (onesBigest === true) {
            ev.preventDefault();

            currentPosX = currentPosX + (newPosX - lastPosX);

            if (currentPosX > menuWidth) {
                currentPosX = menuWidth;
            }

            if (currentPosX < 0) {
                currentPosX = 0;
            }

            setTranslateX(siteMenuElem, currentPosX/menuWidth*100 +'%');
            //setTranslateX(siteCanvasElem, currentPosX/3);

            siteCloseElem.style.opacity = currentPosX/menuWidth;
        }
    }

    lastPosX = newPosX;
}

function dragEnd(event) {
    var ev = event.originalEvent || event || window.event;

    ev.target = ev.target || ev.srcElement;

    if (newPosX > 0) {
        direction = "right";
    } else {
        direction = "left";
    }

    if ( (currentPosX > changePoint && direction === "right")
            || (currentPosX < menuWidth - changePoint && direction === "left") )
    {
        var proportion = currentPosX / menuWidth,
            slideTime;

        if ( (direction === "right")) {
            /* open menu */
            slideTime = timeToggle * (1 - proportion);

            setDuration(siteMenuElem, slideTime);
            //setDuration(siteCanvasElem, slideTime);
            setDuration(siteCloseElem, slideTime);

            showMenu();
        }

        if ( (direction === "left")) {
            /* close menu */
            slideTime = timeToggle * proportion;

            setDuration(siteMenuElem, slideTime);
            //setDuration(siteCanvasElem, slideTime);
            setDuration(siteCloseElem, slideTime);

            hideMenu();

            afterHide(slideTime);
        }
    } else {

        if (newPosX !== 0) {
            /* go back to current status */
            if (menuStatus === "closed") {
                setDuration(siteMenuElem, timeToggle);
                //setDuration(siteCanvasElem, timeToggle);
                setDuration(siteCloseElem, timeToggle);

                currentPosX = 0;

                setTranslateX(siteMenuElem, currentPosX+'%');
                //setTranslateX(siteCanvasElem, currentPosX);

                siteCloseElem.style.opacity = 0;

                afterHide();
            }

            if (menuStatus === "opened") {
                clearTimeout(moved);
                setDuration(siteMenuElem, timeToggle);
                //setDuration(siteCanvasElem, timeToggle);
                setDuration(siteCloseElem, timeToggle);

                currentPosX = menuWidth;

                setTranslateX(siteMenuElem, currentPosX/menuWidth*100 + '%');
                //setTranslateX(siteCanvasElem, currentPosX/3);

                siteCloseElem.style.opacity = 1;
            }
        }
    }

    swapEvents("off");
}

if ( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
    document.addEventListener(evStart, dragStart, false);
}

/* --- Menu End --- */
};

export default sidebar;