/* ------------------ Social Auth ------------------ */
function SocialAuth(config) {
    if (! config || typeof config !== 'object') {
        throw 'Config has to be specified';
    }
    if (! config.redirectUrl) {
        throw 'Redirect URL must be specified';
    }
    if (! config.responseUrl) {
        throw 'Response URL must be specified';
    }
    this.redirectOnSuccess = (config.redirectOnSuccess) ? config.redirectOnSuccess : '';
    this.redirectUrl       = config.redirectUrl;
    this.responseUrl       = config.responseUrl;
    this.redirectUrlParam  = (config.redirectUrlParam) ? config.redirectUrlParam : 'provider';
    this.registerUrlParam  = (config.registerUrlParam) ? config.registerUrlParam : '';
    this.pollInterval      = (config.pollInterval) ? config.pollInterval : 500;
    this.authInProgress    = false;
    this.popupWidth        = (config.popupWidth) ? config.popupWidth : 800;
    this.popupHeight       = (config.popupHeight) ? config.popupHeight : 600;
    this.btnClass          = (config.btnClass) ? config.btnClass : 'social-auth' ;
    this.providerAttr      = (config.providerAttr) ? config.providerAttr : 'sa-provider';
    this.buttons           = null;
    this.onPreAuth         = (config.onPreAuth) ? config.onPreAuth : this.onPreAuth;
    this.onFailure         = (config.onFailure) ? config.onFailure : this.onFailure;
    this.onPopupClose      = (config.onPopupClose) ? config.onPopupClose : this.onPopupClose;
}
SocialAuth.prototype.init = function() {
    this.buttons = this.getButtons();
    this.attachEvents();
};
SocialAuth.prototype.getButtons = function() {
    return document.getElementsByClassName(this.btnClass);
};
SocialAuth.prototype.attachEvents = function() {
    for (var i = 0; i < this.buttons.length; i++) {
        this.buttons[i].addEventListener('click', (this.btnClickEvent).bind(this));
    }
};
SocialAuth.prototype.parseUrlParams = function(url) {
    var queryStr = url.split('?')[1];
    var params = {};
    if (! queryStr) {
        throw 'Invalid response URL';
    }
    // Remove unnecessary strings after # char
    queryStr = queryStr.split('#')[0];
    // Seperate params
    var paramsArr = queryStr.split('&');
    for (var i = 0; i < paramsArr.length; i++) {
        // Seperate key-value
        var param = paramsArr[i].split('=');
        // Add param to object
        params[param[0]] = (typeof(param[1]) === 'undefined') ? true : param[1];
    }
    return params;
};
SocialAuth.prototype.handleResponse = function(url) {
    var params = this.parseUrlParams(url);
    // Check if status code is given
    if (! params.status_code) {
        throw 'Invalid response';
    }
    var status = parseInt(params.status_code);
    var description = params.status_description;
    switch (status) {
        case 200:
            // OK. Redirect.
            if (this.redirectOnSuccess) {
                location.replace(this.redirectOnSuccess);
            } else {
                location.reload(true);
            }
            break;
        case 201:
            // User registered
            if (!this.redirectOnSuccess && !this.registerUrlParam) {
                location.reload(true);
            }
            var link = (this.redirectOnSuccess) ? this.redirectOnSuccess : location.href;
            location.replace(link);
            break;
        default:
            // Unlock in-progress state
            this.authInProgress = false;
            // Trigger event
            this.onFailure({status: status, description: description});
            let message = document.createElement("div");
            message.className = "checkout__form-message error active mt-3";
            let messageImg = document.createElement("img");
            messageImg.src = "/assets-uk/images/svg/md-alert.svg";
            let messageText = document.createElement("p");
            messageText = description;
            message.appendChild(messageImg);
            message.appendChild(messageText);
            let messageContainer = document.querySelector(".message-container");
            if (messageContainer) {
                messageContainer.appendChild(message);
            }
            break;
    }
};
SocialAuth.prototype.btnClickEvent = function(e) {
    e.preventDefault();
    if (this.authInProgress) {
        return;
    }
    // Trigger event
    this.onPreAuth();
    // Get provider attribute from the element
    var provider = e.target.attributes.getNamedItem(this.providerAttr);
    if (provider) {
        // Set in progress state
        this.authInProgress = true;
        // Popup parameters
        var url    = this.redirectUrl + '/' + provider.value;
        var name   = provider.name + "_login_popup";
        var params = 'width=' + this.popupWidth + ', height=' + this.popupHeight;
        // Create popup
        var popup = window.open(url, name, params);
        var pollTimer = window.setInterval((function() {
            try {
                // Check if popup was closed by the user
                if (popup.closed) {
                    window.clearInterval(pollTimer);
                    this.authInProgress = false;
                    // Trigger event
                    this.onPopupClose();
                }
                // Check if URL matches to Response URL
                var url = popup.document.URL;
                if (url.indexOf(this.responseUrl) != -1) {
                    window.clearInterval(pollTimer);
                    popup.close();
                    this.handleResponse(url);
                }
            } catch (e) {
            }
        }).bind(this), this.pollInterval);
        return;
    }
    throw 'Cannot find "' + this.providerAttr + '" attribute on the element';
};
SocialAuth.prototype.onFailure    = function(error) {};
SocialAuth.prototype.onPreAuth    = function(e) {};
SocialAuth.prototype.onPopupClose = function(e) {};
/* ---------------- Social Auth END ---------------- */

export default SocialAuth;
