

const sliders = (Swiper) => {

    // var booksSlider = new Swiper ('.books-module__swiper', {
    //     slidesPerView: 'auto',
    //     initialSlide: 5,
    //     effect: 'coverflow',
    //     loop: true,
    //     spaceBetween: 20,
    //     coverflowEffect: {
    //         rotate: 0,
    //         slideShadows: false,
    //         depth: 250,
    //         stretch: 700,
    //     },
    //     navigation: {
    //         nextEl: '.books-module__next',
    //         prevEl: '.books-module__prev'
    //     }
    // });



    var otherSlider = new Swiper ('.js-recommendations-swiper', {
        slidesPerView:'auto',
        spaceBetween: 30,
        freeModeMomentumRatio: 0.3,
        centeredSlides: true,
        loop: true,
        breakpoints: {
            767: {
                spaceBetween: 20
            }
        },
        navigation: {
            nextEl: '.recommendations-next',
            prevEl: '.recommendations-prev'
        }
    });

    var lastViewedSlider = new Swiper ('.js-last-viewed-swiper', {
        slidesPerView:'auto',
        spaceBetween: 23,
        freeMode: true,
        freeModeMomentumRatio: 0.3,
        breakpoints: {
            767: {
                spaceBetween: 12
            }
        }
    });

    var archiveSlider = new Swiper ('.js-archive-swiper', {
        slidesPerView:'auto',
        spaceBetween: 30,
        freeMode: true,
        freeModeMomentumRatio: 0.3,
        breakpoints: {
            767: {
                spaceBetween: 12
            },
            1280: {
                spaceBetween: 20
            }
        }
    });

    var otherSlider = new Swiper ('.js-other-swiper', {
        slidesPerView:'auto',
        spaceBetween: 30,

        breakpoints: {
            767: {
                spaceBetween: 20
            }
        },
        navigation: {
            nextEl: '.product-other .swiper-button-next',
            prevEl: '.product-other .swiper-button-prev',
        },
    });

    if(/MSIE \d|Trident.*rv:/.test(navigator.userAgent)) {
        var segmentBlackSlider = new Swiper ('.js-recommend-swiper', {
            effect: 'fade',
            speed: 300,
            fadeEffect: {
                crossFade: true
            },
            navigation: {
                nextEl: '.recommend-next',
                prevEl: '.recommend-prev'
            }
        });
    } else {
        var segmentBlackSlider = new Swiper ('.js-recommend-swiper', {
            effect: 'fade',
            speed: 300,
            loop: true,
            autoplay: {
                delay: 4000,
            },
            fadeEffect: {
                crossFade: true
            },
            navigation: {
                nextEl: '.recommend-next',
                prevEl: '.recommend-prev'
            },
            pagination: {
                el: '.js-recommend-swiper .swiper-pagination',
                type: 'bullets',
                clickable: true,
            },
        });
    }

    var segmentBlackSlider = new Swiper ('.js-segment-black-swiper', {
        spaceBetween: 30,
        watchOverflow: true,
        navigation: {
            nextEl: '.black-next',
            prevEl: '.black-prev'
        }
    });

    let segmentSlider = document.querySelector(".js-segment-swiper");
    if (segmentSlider) {
        const listItems = document.getElementsByClassName("c-segment__list-item");
        const list = document.getElementsByClassName("c-segment__list")[0];
        const segmentSwiper = new Swiper (segmentSlider, {
            spaceBetween: 30,
            noSwipingClass: 'swiper-container'
        });
        if (listItems.length > 0) {
            list.addEventListener("click", function(e){
                if (e.target.dataset.key) {
                    for (var i = 0; i < listItems.length; i++) {
                        listItems[i].classList.remove("active");
                    }
                    e.target.classList.add("active");
                    segmentSwiper.slideTo(e.target.dataset.key);
                }
            });
        }
    }

    segmentBlackSlider.on('slideNextTransitionStart', function () {
        segmentSlider.slideNext();
    });
    segmentBlackSlider.on('slidePrevTransitionStart', function () {
        segmentSlider.slidePrev();
    });

    let specialEdition = new Swiper ('.special-edition--edition .swiper-container', {
        slidesPerView: "auto",
        centeredSlides: true,
        loop: true,
        initialSlide: 2,
        navigation: {
            nextEl: '.special-edition--edition .special-edition__next',
            prevEl: '.special-edition--edition .special-edition__prev',
        },
    });

    let booksSlider = new Swiper ('.special-edition--books .swiper-container', {
        slidesPerView: "auto",
        centeredSlides: true,
        loop: true,
        initialSlide: 2,
        navigation: {
            nextEl: '.special-edition--books .special-edition__next',
            prevEl: '.special-edition--books .special-edition__prev',
        },
    });

    let swiperPromo = new Swiper(".swiper-container.swiper-promo", {
        slidesPerView: 1,
        loop: true,
        initialSlide: 0,
        autoplay: {
            delay: 4000,
        },
        loopAdditionalSlides: 3,
        navigation: {
            nextEl: '.swiper-promo__next',
            prevEl: '.swiper-promo__prev',
        }
    });
};

export default sliders;
